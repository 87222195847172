<template>
  <div class="d-flex flex-column justify-between">
    <div class="text--primary flex-grow-1">
      <p class="text--primary text-center text-xl font-weight-bold">
        {{ $t("Make the payment to") }}:
      </p>
      <v-row :class="[$vuetify.theme.isDark ? '' : 'bg-light-gray', 'my-1']">
        <v-col cols="5" class="pa-1 px-4"> {{ $t("Account holder") }} </v-col>
        <v-col cols="7" class="text-end pa-1 font-weight-bold">
          {{ bank.accountHolder }}
          <span class="ml-2">
            <v-tooltip top v-model="showTooltip.showTooltipAccountHolder">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  @click="
                    handleCopyClipboard(
                      bank.accountHolder,
                      'showTooltipAccountHolder'
                    )
                  "
                  small
                >
                  <v-icon small>{{ icons.mdiContentCopy }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Account holder copied!") }}</span>
            </v-tooltip>
          </span>
        </v-col>
      </v-row>
      <v-row :class="[$vuetify.theme.isDark ? '' : 'bg-light-gray', 'my-1']">
        <v-col cols="5" class="pa-1 px-4">{{ $t("Email") }} </v-col>
        <v-col cols="7" class="text-end pa-1 font-weight-bold">
          {{ bank.email }}
          <span class="ml-2">
            <v-tooltip top v-model="showTooltip.showTooltipEmailHolder">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  @click="
                    handleCopyClipboard(bank.email, 'showTooltipEmailHolder')
                  "
                  small
                >
                  <v-icon small>{{ icons.mdiContentCopy }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Email copied!") }}</span>
            </v-tooltip>
          </span>
        </v-col>
      </v-row>

      <v-row :class="[$vuetify.theme.isDark ? '' : 'bg-light-gray', 'my-1']">
        <v-col cols="5" class="pa-1 px-4">{{ $t("Memo number") }} </v-col>
        <v-col cols="7" class="text-end pa-1 font-weight-bold">
          {{ bank.memoNumber }}
          <span class="ml-2">
            <v-tooltip top v-model="showTooltip.showTooltipMemoNumber">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  @click="
                    handleCopyClipboard(bank.email, 'showTooltipMemoNumber')
                  "
                  small
                >
                  <v-icon small>{{ icons.mdiContentCopy }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Memo number copied!") }}</span>
            </v-tooltip>
          </span>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>

      <p class="text-xl font-weight-bold">
        {{ $t("Enter the details of the zelle made") }}
      </p>

      <ValidationProvider
        :name="$t('Payment date')"
        rules="required"
        v-slot="{ errors }"
      >
        <DatePickerInput
          :label="$t('Payment date')"
          :placeholder="$t('Enter your payment date')"
          v-model="zelleForm.date"
          outlined
          dense
          :max="maxDay"
          :error-messages="errors[0] && errors[0]"
        />
      </ValidationProvider>

      <ValidationProvider
        :name="$t('Memo number')"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          :label="$t('Memo number')"
          v-model="zelleForm.memo"
          outlined
          dense
          :error-messages="errors[0] && errors[0]"
        >
        </v-text-field>
      </ValidationProvider>

      <!-- <ValidationProvider
        name="Monto enviado"
        rules="required"
        v-slot="{ errors }"
      > -->
      <v-text-field
        :label="$t('Amount sent')"
        v-model.lazy="zelleForm.amount"
        v-money="money"
        prefix="$"
        outlined
        dense
      >
      </v-text-field>
      <!-- </ValidationProvider> -->

      <ValidationProvider
        :name="$t('Zelle owner')"
        rules="required|min:2|max:100"
        v-slot="{ errors }"
      >
        <v-text-field
          :label="$t('Zelle owner')"
          v-model="zelleForm.ownerInput"
          outlined
          dense
          :error-messages="errors[0] && errors[0]"
        >
        </v-text-field>
      </ValidationProvider>

      <p class="text--blue blue-border py-3 mt-3">
        <v-icon style="color: #16b1ff !important">
          {{ icons.mdiInformationOutline }}
        </v-icon>
        <span class="pl-2">
          {{
            $t("The zelle owner is the person or entity that made the payment")
          }}.
        </span>
      </p>

      <v-divider class="my-4"></v-divider>
      <ValidationProvider
        :name="$t('Do you want a custom invoice?')"
        rules="required"
        v-slot="{ errors }"
        slim
      >
        <v-radio-group
          v-model="zelleForm.customInvoice"
          :error-messages="errors[0] && errors[0]"
          :label="$t('Do you want a custom invoice?')"
          dense
          row
        >
          <template v-slot:label>
            <div class="text--primary font-weight-bold">
              {{ $t("Do you want a custom invoice?") }}
            </div>
          </template>
          <v-radio :label="$t('Yes')" :value="true">
            <template v-slot:label>
              <div class="text--primary">
                {{ $t("Yes") }}
              </div>
            </template>
          </v-radio>
          <v-radio class="text--primary" :label="$t('No')" :value="false">
            <template v-slot:label>
              <div class="text--primary">
                {{ $t("No") }}
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </ValidationProvider>
      <ValidationProvider
        :name="$t('Is this top up related to a proform?')"
        rules="required"
        v-slot="{ errors }"
        slim
      >
        <v-radio-group
          :error-messages="errors[0] && errors[0]"
          :label="$t('Is this top up related to a proform?')"
          dense
          row
          v-model="zelleForm.proforma"
        >
          <template v-slot:label>
            <div class="text--primary font-weight-bold">
              {{ $t("Is this top up related to a proform?") }}
            </div>
          </template>
          <v-radio :label="$t('Yes')" :value="true">
            <template v-slot:label>
              <div class="text--primary">
                {{ $t("Yes") }}
              </div>
            </template>
          </v-radio>
          <v-radio :label="$t('No')" :value="false">
            <template v-slot:label>
              <div class="text--primary">
                {{ $t("No") }}
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </ValidationProvider>
      <ValidationProvider
        v-if="zelleForm.proforma"
        :name="$t('Proforma number')"
        rules="required"
        v-slot="{ errors }"
        slim
      >
        <v-text-field
          v-model="zelleForm.proformaNumber"
          :label="$t('Proforma')"
          outlined
          dense
          :error-messages="errors[0] && errors[0]"
        >
        </v-text-field>
      </ValidationProvider>
    </div>

    <!-- Botones para volver y continuar -->
    <div class="button-group">
      <v-btn
        rounded
        outlined
        style="width: 40%"
        @click="$emit('closePaymentDrawer')"
      >
        {{ $t("Back") }}
      </v-btn>
      <v-btn
        rounded
        style="width: 40%"
        color="primary"
        :disabled="handleDisabled"
        @click="handleNextStep"
        :loading="loading"
      >
        {{ $t("Continue") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiAlert,
  mdiContentCopy,
  mdiInformationOutline,
} from "@mdi/js";
import { copyTextToClipboard } from "@/mixins/copyToClipboardMixin";
import DatePickerInput from "@/components/DatePickerInput";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";

export default {
  name: "ZelleStep1",
  mixins: [copyTextToClipboard],
  components: {
    DatePickerInput,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      amount: 0,
      money: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      bank: {
        email: "zelrydery@gmail.com",
        accountHolder: "JS LOGISTICS SOLUTIONS LLC",
        memoNumber: "RIDE-XXXXXX",
      },
      icons: {
        mdiMagnify,
        mdiAlert,
        mdiContentCopy,
        mdiInformationOutline,
      },
      showTooltip: {
        showTooltipAccountHolder: false,
        showTooltipEmailHolder: false,
        showTooltipMemoNumber: false,
      },
      zelleForm: {
        date: "",
        memo: "",
        ownerInput: "",
        customInvoice: undefined,
        proforma: undefined,
        proformaNumber: "",
        value: 0,
      },
      loading: false,
    };
  },
  methods: {
    formatCurrency() {
      // Format amount to currency
      this.amount = parseFloat(this.amount).toFixed(2);
    },
    handleCopyClipboard(value, tooltip = null) {
      this.copyTextToClipboard(value);
      if (tooltip && !this.showTooltip[tooltip]) {
        this.showTooltip[tooltip] = true;
        setTimeout(() => {
          this.showTooltip[tooltip] = false;
        }, 2000);
      }
    },
    async handleNextStep() {
      try {
        this.loading = true;
        const {
          amount,
          ownerInput,
          date,
          memo,
          customInvoice,
          proforma,
          proformaNumber,
        } = this.zelleForm;
        const params = {
          date: date,
          amount: Number(amount.replace(/,/g, "")),
          memo: memo,
          ownerInput: ownerInput,
          customInvoice: !!customInvoice,
          proforma: proforma ? proformaNumber : "",
        };
        const response = await axios.post(
          `${process.env.VUE_APP_STAGING}/v2/corporate/add-zelle`,
          params
        );
        this.data.success = response.data?.success;
        this.data.currentBalance = response.data.data.total_wallet_amount;
        this.data.rechargeAmount = response.data.data.wallet_amount;
      } catch (error) {
        console.log(error);
        this.data.success = false;
      } finally {
        this.loading = false;
        this.$emit("next");
      }
    },
  },
  computed: {
    maxDay() {
      let today = new Date();
      return this.$moment(today).add(-4, "hours").toISOString();
    },
    ...mapState("auth", ["unique_id"]),
    handleDisabled() {
      return (
        !this.zelleForm.date ||
        !this.zelleForm.memo ||
        !this.zelleForm.ownerInput ||
        this.zelleForm.ownerInput.length < 2 ||
        !this.zelleForm.amount ||
        this.zelleForm.amount <= 0
      );
    },
  },
  mounted() {
    let baseMemo = `${this.unique_id}`;
    while (baseMemo.length < 6) {
      baseMemo = "0" + baseMemo;
    }
    this.bank.memoNumber = "RIDE-" + baseMemo;
    this.zelleForm.memo = this.bank.memoNumber;
  },
};
</script>

<style scoped>
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.bg-light-gray {
  background-color: #fafafa !important;
}

.blue-border {
  border: 1px solid #16b1ff;
  padding: 5px;
  border-radius: 4px;
}

.text--blue {
  color: #16b1ff;
}
</style>
