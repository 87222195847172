<template>
  <div>
    <div class="d-flex flex-column justify-content-between">
      <v-row dense>
        <v-col cols="12">
          <v-card class="d-flex flex-column justify-md-start justify-center">
            <v-row class="ma-0 pa-2 d-flex flex-column align-start" dense>
              <div class="d-flex">
                <h2>{{ $t("Balance") }}</h2>
                <v-tooltip top :max-width="300">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      medium
                      v-on="on"
                      class="ml-md-6 ml-2 color-gray-text"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span>{{ $t("tooltipBalance") }}</span>
                </v-tooltip>
              </div>
            </v-row>
            <v-divider class=""></v-divider>

            <v-row class="ma-0 pa-4 d-flex flex-row align-space-around" dense>
              <v-col
                cols="6"
                class="ma-0 pa-0 d-flex flex-row align-center justify-start"
                :class="computedColor"
              >
                <span class="mr-4">
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="48" height="48" rx="8" fill="#F5F5F5" />
                    <path
                      d="M9.99902 14.8284H35.5531C36.9032 14.8284 37.9976 15.9228 37.9976 17.2728V35.5555C37.9976 36.9055 36.9032 38 35.5531 38H12.4435C11.0934 38 9.99902 36.9056 9.99902 35.5555V14.8284Z"
                      fill="#3BD4AE"
                    />
                    <path
                      d="M9.99902 12.5464H24.8203C26.1704 12.5464 27.2648 13.6408 27.2648 14.9908V30.6989C27.2648 32.0489 26.1704 33.1434 24.8203 33.1434H12.4435C11.0934 33.1434 9.99902 32.0489 9.99902 30.6989V12.5464Z"
                      fill="#3BD4AE"
                    />
                    <path
                      d="M9.99902 12.4137C9.99902 11.0807 11.0797 10 12.4127 10H24.3039C26.9701 10 29.1314 12.1613 29.1314 14.8274H12.4127C11.0797 14.8274 9.99902 13.7468 9.99902 12.4137Z"
                      fill="#278870"
                    />
                    <path
                      d="M26.6455 26.4128C26.6455 24.1021 28.5186 22.229 30.8293 22.229H37.2952C37.6847 22.229 38.0005 22.5448 38.0005 22.9343V29.8912C38.0005 30.2808 37.6847 30.5965 37.2952 30.5965H30.8293C28.5186 30.5965 26.6455 28.7234 26.6455 26.4128Z"
                      fill="#278870"
                    />
                  </svg>
                </span>
                <span class="d-flex flex-column">
                  <span
                    class="font-weight-bold text-md-h4 text-h6 text--primary"
                  >
                    {{ wallet | currency }}
                  </span>
                  <span class="ml-1">
                    {{ $t("Available") }}
                  </span>
                </span>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="ma-0 pa-0 d-flex flex-row align-center justify-end"
                :class="computedColor"
              >
                <v-btn
                  rounded
                  color="primary"
                  class="d-flex flex-row align-center"
                  @click="rechargeDrawer = !rechargeDrawer"
                >
                  <span>
                    <v-icon medium style="color: white !important" class="mr-2">
                      {{ icons.mdiCached }}
                    </v-icon>
                  </span>
                  {{ $t("Recharge") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-navigation-drawer
        v-model="rechargeDrawer"
        fixed
        temporary
        right
        :width="drawerWidth"
        style="height: 100dvh"
        class="drawer"
      >
        <v-container fluid class="ma-0 pa-0">
          <v-card flat class="ma-0 pa-0">
            <v-btn icon @click="rechargeDrawer = false">
              <v-icon color="primary">{{ icons.mdiClose }}</v-icon>
            </v-btn>
            <v-divider />
            <p class="mb-0 mt-2 ml-4 text--primary font-weight-bold">
              {{ $t("Select payment method") }}
            </p>
            <div class="px-4">
              <TopUps
                @closeTopUpsDrawer="rechargeDrawer = false"
                @refreshManualPayments="getPendingManualPayments"
                @refreshPayments="getCorporatePayments"
              />
            </div>
          </v-card>
        </v-container>
      </v-navigation-drawer>

      <v-row dense class="mt-5">
        <v-col cols="12">
          <v-tabs v-model="selectedTab">
            <v-tab>{{ $t("BALANCE HISTORY") }}</v-tab>
            <v-tab :disabled="!pendingValidationTable.total">
              {{ $t("MANUAL TOP UPS") }}
              <v-avatar color="success" size="25" class="ml-2 white--text">
                {{ pendingValidationTable.total }}
              </v-avatar>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab">
            <v-tab-item>
              <PaymentTableTab
                :table="table"
                :icons="icons"
                :new_="new_"
                @pageChange="handlePageChange"
                @refreshTables="getCorporatePayments"
                @getCorporatePaymentsFilter="getCorporatePaymentsFilter"
                @getPendingManualPayments="getPendingManualPayments"
                @getCorporatePaymentsExcelFilter="
                  getCorporatePaymentsExcelFilter
                "
                @updateDate="updateDate"
              />
              <!-- Placeholder de nuevos -->
              <v-row v-if="new_" class="custom-margin-2">
                <v-col
                  cols="12"
                  class="d-flex flex-column justify-center align-center"
                >
                  <v-img
                    src="../Balance/assets/typography_es.png"
                    width="350"
                    v-if="!$vuetify.theme.isDark"
                  ></v-img>
                  <v-img
                    src="../Balance/assets/typography_es_dark.png"
                    width="350"
                    v-else
                  ></v-img>
                </v-col>
              </v-row>
            </v-tab-item>

            <!-- <v-row>
      <v-col cols="1">
        <v-switch v-model="new_" label="nuevo" />
      </v-col>
    </v-row> -->

            <!-- Recargas manuales pendientes -->
            <v-tab-item>
              <ManualTopUpsTableTab
                :pendingValidationTable="pendingValidationTable"
                :icons="icons"
                :new_="new_"
                @refreshTables="getPendingManualPayments"
                @pendingTablePageChange="handlePendingTablePageChange"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import {
  mdiInformationOutline,
  mdiMagnify,
  mdiCalendar,
  mdiDotsVertical,
  mdiDownload,
  mdiPlus,
  mdiWallet,
  mdiWalletPlus,
  mdiChevronDown,
  mdiToggleSwitchOffOutline,
  mdiToggleSwitchOutline,
  mdiClose,
  mdiCached,
} from "@mdi/js";
import axios from "@/plugins/axios";
import { mapActions, mapState } from "vuex";

import generateSocketIdMixin from "@/mixins/generateSocketIdMixin";
import ExportSocketDialog from "../../components/ExportSocketDialog.vue";

import DataTable from "./parts/DataTable.vue";
import AddPagoMovilAutomatizadoModal from "./parts/AddPagoMovilAutomatizadoModal";
import AddPagoMovilAutomatizadoModalEN from "./parts/AddPagoMovilAutomatizadoModalEN";
import AddManualTopUpModal from "./parts/AddManualTopUpModal";
import AddZelleModal from "./parts/AddZelleModal";
import AddZelleModalEN from "./parts/AddZelleModalEN";
import DataTablePendingManualPayments from "./parts/DataTablePendingManualPayments.vue";
import TopUps from "./parts/TopUps.vue";
import ProformModal from "./parts/ProformModal.vue";
import PaymentTableTab from "./parts/PaymentTableTab.vue";
import ManualTopUpsTableTab from "./parts/ManualTopUpsTableTab.vue";

export default {
  name: "Balance",
  mixins: [generateSocketIdMixin],
  components: {
    DataTable,
    AddPagoMovilAutomatizadoModal,
    AddPagoMovilAutomatizadoModalEN,
    AddManualTopUpModal,
    AddZelleModal,
    AddZelleModalEN,
    DataTablePendingManualPayments,
    TopUps,
    ExportSocketDialog,
    PaymentTableTab,
    ManualTopUpsTableTab,
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiMagnify,
        mdiCalendar,
        mdiDotsVertical,
        mdiDownload,
        mdiPlus,
        mdiWallet,
        mdiWalletPlus,
        mdiChevronDown,
        mdiToggleSwitchOffOutline,
        mdiToggleSwitchOutline,
        mdiClose,
        mdiCached,
      },
      date: [],
      menu: false,
      new_: false,
      table: {
        movements: [],
        tableLoading: false,
        headers: [
          { text: "ID", value: "unique_id", align: "start" },
          { text: "FECHA Y HORA", value: "created_at", align: "start" },
          { text: "BALANCE INICIAL", value: "wallet_amount", align: "end" },
          { text: "AGREGADO/RESTADO", value: "add_cut", align: "end" },

          {
            text: "BALANCE FINAL",
            value: "total_wallet_amount",
            align: "end",
          },
          {
            text: "ORIGEN DE TRANSACCIÓN",
            value: "wallet_description",
            align: "start",
          },
          {
            text: "NOTA",
            value: "add_wallet_comment",
            align: "start",
          },
        ],
        currentPage: 1,
        pages: 1,
        searchItem: null,
        limit: this.$vuetify.breakpoint.mdAndUp ? 10 : 5,
        search: "",
        iconDown: true,
      },
      pendingManualTopUpsHidden: true,
      pendingManualTopUpsHiddenTable: false,
      pendingValidationTable: {
        headers: [
          { text: "ID", value: "unique_id", align: "start" },
          { text: "FECHA DE RECARGA", value: "payment_at", align: "start" },
          { text: "FECHA ENVIADO", value: "created_at", align: "start" },
          { text: "TIPO", value: "payment_name", align: "start" },
          { text: "MONEDA", value: "currency_id", align: "start" },
          { text: "MONTO", value: "amount", align: "end" },
          {
            text: "FACTURA PERSONALIZADA",
            value: "invoice_personalized",
            align: "center",
          },
          {
            text: "ACCIÓN",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ],
        currentPage: 1,
        pages: 1,
        limit: this.$vuetify.breakpoint.mdAndUp ? 10 : 5,
        items: [],
        loading: false,
        total: 0,
      },
      rechargeDrawer: false,
      selectedTab: 0,
    };
  },
  methods: {
    ...mapActions("auth", ["meNotLoading"]),
    ...mapActions(["setLoading"]),
    ...mapActions("showProformModal", ["updateDontShowAgain"]),
    async getCorporatePayments() {
      this.table.tableLoading = true;
      let params = {
        page: this.computedPage,
        limit: this.table.limit,
      };
      if (this.table.searchItem) {
        let search_item = "";
        if (this.table.searchItem === this.$t("By admin")) {
          search_item = 1;
        }
        if (this.table.searchItem === this.$t("User amount")) {
          search_item = 2;
        }
        if (this.table.searchItem === this.$t("Charge of this trip")) {
          search_item = 3;
        }
        if (this.table.searchItem === this.$t("Card")) {
          search_item = 4;
        }
        if (this.table.searchItem === this.$t("Charge of this parcel")) {
          search_item = 5;
        }
        if (this.table.searchItem === this.$t("Pago móvil automatizado")) {
          search_item = 6;
        }
        if (this.table.searchItem === this.$t("automated_zelle")) {
          search_item = 7;
        }
        if (this.table.searchItem === this.$t("manual_top_up_request")) {
          search_item = 8;
        }
        if (this.table.searchItem === this.$t("corporate_master_assign")) {
          search_item = 9;
        }
        params["transaction_type"] = search_item;
      }
      if (this.date) {
        if (this.date.length >= 1) {
          let sorted = this.date.sort();
          params["start_date"] = `${sorted[0]}`;
          if (sorted.length === 1) {
            params["end_date"] = `${sorted[0]}`;
          }
          if (sorted.length > 1) {
            params["end_date"] = `${sorted[1]}`;
          }
        }
      }
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_STAGING}/v2/corporate/payments`,
          { params: params }
        );
        if (data?.success) {
          this.table.movements = [];
          data.data.detail.forEach((trip) => {
            // trip = this.handleOriginTranslate(trip);
            this.table.movements.push(trip);
          });
          this.table.pages = data.data.pages;
          this.table.currentPage = parseFloat(data.data.current_page);
          if (data.data.detail.length >= 1) {
            this.new_ = false;
          } else {
            this.new_ = true;
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.meNotLoading();

      this.table.tableLoading = false;
    },
    async getCorporatePaymentsFilter() {
      this.table.currentPage = 1;
      this.table.tableLoading = true;
      let params = {
        page: 1,
        limit: this.table.limit,
      };
      if (this.table.searchItem) {
        let search_item = "";
        if (this.table.searchItem === this.$t("By admin")) {
          search_item = 1;
        }
        if (this.table.searchItem === this.$t("User amount")) {
          search_item = 2;
        }
        if (this.table.searchItem === this.$t("Charge of this trip")) {
          search_item = 3;
        }
        if (this.table.searchItem === this.$t("Card")) {
          search_item = 4;
        }
        if (this.table.searchItem === this.$t("Charge of this parcel")) {
          search_item = 5;
        }
        if (this.table.searchItem === this.$t("Pago móvil automatizado")) {
          search_item = 6;
        }
        if (this.table.searchItem === this.$t("automated_zelle")) {
          search_item = 7;
        }
        if (this.table.searchItem === this.$t("manual_top_up_request")) {
          search_item = 8;
        }
        if (this.table.searchItem === this.$t("corporate_master_assign")) {
          search_item = 9;
        }
        params["transaction_type"] = search_item;
      }
      if (this.date) {
        if (this.date.length >= 1) {
          let sorted = this.date.sort();
          params["start_date"] = `${sorted[0]}`;
          if (sorted.length === 1) {
            params["end_date"] = `${sorted[0]}`;
          }
          if (sorted.length > 1) {
            params["end_date"] = `${sorted[1]}`;
          }
        }
      }
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_STAGING}/v2/corporate/payments`,
          {
            params: params,
          }
        );
        if (!!data.success) {
          this.table.movements = [];
          data.data.detail.forEach((trip) => {
            // trip = this.handleOriginTranslate(trip);
            this.table.movements.push(trip);
          });
          this.table.pages = data.data.pages;
          this.table.currentPage = parseFloat(data.data.current_page);
          if (data.data.detail.length >= 1) {
            this.new_ = false;
          } else {
            this.new_ = true;
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.meNotLoading();

      this.table.tableLoading = false;
    },
    async getCorporatePaymentsExcelFilter() {
      try {
        const socketId = this.generateSocketId("providersExport");
        let queryParams = {};
        if (this.table.searchItem) {
          let search_item = "";
          if (this.table.searchItem === this.$t("By admin")) {
            search_item = 1;
          }
          if (this.table.searchItem === this.$t("User amount")) {
            search_item = 2;
          }
          if (this.table.searchItem === this.$t("Charge of this trip")) {
            search_item = 3;
          }
          if (this.table.searchItem === this.$t("Card")) {
            search_item = 4;
          }
          if (this.table.searchItem === this.$t("Charge of this parcel")) {
            search_item = 5;
          }
          if (this.table.searchItem === this.$t("Pago móvil automatizado")) {
            search_item = 6;
          }
          if (this.table.searchItem === this.$t("automated_zelle")) {
            search_item = 7;
          }
          if (this.table.searchItem === this.$t("manual_top_up_request")) {
            search_item = 8;
          }
          if (this.table.searchItem === this.$t("corporate_master_assign")) {
            search_item = 9;
          }
          queryParams["transaction_type"] = search_item;
        }
        if (this.date) {
          if (this.date.length >= 1) {
            let sorted = this.date.sort();
            queryParams["start_date"] = `${sorted[0]}`;
            if (sorted.length === 1) {
              queryParams["end_date"] = `${sorted[0]}`;
            }
            if (sorted.length > 1) {
              queryParams["end_date"] = `${sorted[1]}`;
            }
          }
        }
        queryParams["socketId"] = socketId;
        axios
          .get(`${process.env.VUE_APP_STAGING}/v2/corporate/payments/export`, {
            params: queryParams,
            timeout: 1800000, // 30 minutes in milliseconds
          })
          .then((response) => {
            console.log(
              "🚩🚩🚩- DM ~ ./V2/corporate/payments/export ~ response:",
              response
            );
          })
          .catch((error) => {
            console.log("🚩🚩🚩- DM ~ handleExport ~ error:", error);
            this.$dialog.notify.error(error?.message || "Ha ocurrido un error");
            throw new Error("Error en request");
          });

        const payload = {
          socketId,
        };

        const dialogParams = {
          fullscreen: !this.$vuetify.breakpoint.lgAndUp,
          width: "40%",
          height: "100%",
          transition: "dialog-top-transition",
          payload: payload,
          scrollable: true,
          showClose: false,
          dialogTitle: this.$t("Exporting payments"),
          processingMessage: this.$t("Processing page"),
          ofMessage: this.$t("of"),
          downloadMessage: this.$t("Download"),
          goBackMessage: this.$t("GoBack"),
          expiredMessage: this.$t("expired"),
          youCanDownloadMessage: this.$t("You can download the export file"),
          exportFileHasExpiredMessage: this.$t("The export file has"),
          processingPageMessage: this.$t("Processing pages for Excel file"),
          errorMessage: this.$t("Sorry, there was an error"),
          closeMessage: this.$t("Close"),
        };

        const dialogPromise = await this.$dialog.showAndWait(
          ExportSocketDialog,
          dialogParams
        );

        if (dialogPromise === "closed") {
          console.log("closed");
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
    },
    handlePageChange() {
      this.getCorporatePayments();
    },
    handlePendingTablePageChange() {
      this.getPendingManualPayments();
    },
    handleOriginTranslate(trip) {
      trip.wallet_description = trip.wallet_description.replace(
        "By Admin",
        "Por admin"
      );
      trip.wallet_description = trip.wallet_description.replace(
        "User amount limit",
        trip.wallet_status === 2
          ? "Monto restado del panel corporativo"
          : "Monto agregado del panel corporativo"
      );
      trip.wallet_description = trip.wallet_description.replace(
        "Charge Of This Trip",
        "Cargo del viaje"
      );
      trip.wallet_description = trip.wallet_description.replace(
        "Card",
        "Tarjeta"
      );
      trip.wallet_description = trip.wallet_description.replace(
        "Charge Of This Parcel",
        "Cargo del envío"
      );
      return trip;
    },
    async handlePagoMovilAutomatizadoModal() {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "80%",
        transition: "dialog-top-transition",
        hideCancel: true,
        persistent: true,
      };

      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(
          AddPagoMovilAutomatizadoModal,
          params
        );
        if (dialogInstance) {
          this.getCorporatePayments();
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(
          AddPagoMovilAutomatizadoModalEN,
          params
        );
        if (dialogInstance) {
          this.getCorporatePayments();
        }
      }
    },
    async handleZelleModal() {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "80%",
        transition: "dialog-top-transition",
        hideCancel: true,
        persistent: true,
      };

      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(
          AddZelleModal,
          params
        );
        if (dialogInstance) {
          this.getCorporatePayments();
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(
          AddZelleModalEN,
          params
        );
        if (dialogInstance) {
          this.getCorporatePayments();
        }
      }
    },
    async handleManualTopUps(manualPayment) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "80%",
        transition: "dialog-top-transition",
        hideCancel: true,
        persistent: true,
        payload: manualPayment,
      };
      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(
          AddManualTopUpModal,
          params
        );
        if (dialogInstance) {
          this.getPendingManualPayments();
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(
          AddManualTopUpModal,
          params
        );
        if (dialogInstance) {
          this.getPendingManualPayments();
        }
      }
    },
    async getPendingManualPayments() {
      this.pendingValidationTable.loading = true;
      let params = {
        state_id: 0,
        page: this.pendingValidationComputedPage,
        limit: this.pendingValidationTable.limit,
      };

      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_STAGING}/v2/corporate/manual_top_ups`,
          {
            params: params,
          }
        );
        if (!!data.success) {
          if (data.data.detail.length > 0) {
            this.pendingValidationTable.total = data.data.total;
            this.pendingValidationTable.items = data.data.detail;
            this.pendingValidationTable.pages = data.data.pages;
            this.pendingValidationTable.currentPage = parseFloat(
              data.data.current_page
            );
            this.pendingManualTopUpsHidden = false;
          } else {
            this.pendingManualTopUpsHidden = true;
          }
        } else {
          throw new Error("Error fetching pending manual payments");
        }
      } catch (error) {
        this.pendingManualTopUpsHidden = true;
        this.$dialog.notify.error(error.message);
      }
      this.pendingValidationTable.loading = false;
    },
    handleRefreshTables() {
      this.getPendingManualPayments();
      this.getCorporatePayments();
    },
    handleEmitManualPayment(manualPayment) {
      this.handleManualTopUps(manualPayment);
    },
    updateDate(date) {
      this.date = date;
    },
  },
  created() {
    this.getPendingManualPayments();
    this.getCorporatePayments();
  },
  computed: {
    ...mapState("auth", ["wallet", "overdraft", "is_active_shipments"]),
    ...mapState("showProformModal", ["dontShowAgain"]),
    computedIconStyle() {
      if (this.wallet < 0) {
        return "color: #ef4370 !important;";
      }
      if (this.wallet === 0) {
        return "color: #000522 !important;";
      }
      if (this.wallet > 0) {
        return "color: #3BD4AE !important;";
      }
    },
    computedColor() {
      if (this.wallet < 0) {
        return "color-red-text";
      }
      if (this.wallet === 0) {
        return "color-blue-text";
      }
      if (this.wallet > 0) {
        return "color-green-text";
      }
    },
    computedButtonColor() {
      if (this.wallet < 0) {
        return "accent";
      }
      if (this.wallet === 0) {
        return "primary";
      }
      if (this.wallet > 0) {
        return "success";
      }
    },
    computedBackground() {
      if (this.wallet < 0) {
        return "salmon-background";
      }
      if (this.wallet === 0) {
        return "blue-background";
      }
      if (this.wallet > 0) {
        return "green-background";
      }
    },
    PendingManualPayments() {
      return this.table.movements;
    },
    computedPage() {
      return this.table.currentPage;
    },
    pendingValidationComputedPage() {
      return this.pendingValidationTable.currentPage;
    },
    computedMaxDate() {
      let today = new Date();
      // utc-4 ccs
      return this.$moment(today).add(-4, "hours").toISOString();
    },
    computedTablePendingManualPayments() {
      return this.pendingValidationTable.items;
    },
    drawerWidth() {
      return this.$vuetify.breakpoint.xsOnly ? "100%" : "580px";
    },
  },
  watch: {
    "table.limit": function (oldVal, newVal) {
      if (oldVal > newVal) {
        this.table.currentPage = 1;
      }
      this.getCorporatePayments();
    },
    "table.currentPage": function (newVal, oldVal) {
      if (!newVal) {
        this.table.currentPage = oldVal;
      }
    },
    "pendingValidationTable.limit": function (oldVal, newVal) {
      if (oldVal > newVal) {
        this.pendingValidationTable.currentPage = 1;
      }
      this.getPendingManualPayments();
    },
    "pendingValidationTable.currentPage": function (newVal, oldVal) {
      if (!newVal) {
        this.pendingValidationTable.currentPage = oldVal;
      }
    },
  },
  mounted() {
    if (this.dontShowAgain !== true) {
      this.$dialog.showAndWait(ProformModal, {
        showClose: true,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.salmon-background {
  background-color: rgba(239, 67, 112, 0.08);
}
.blue-background {
  background-color: rgb(0, 5, 34, 0.08);
}
.green-background {
  background-color: rgb(59, 212, 174, 0.08);
}
.custom-font-size {
  font-size: 48px;
}
.custom-font-size-small {
  font-size: 24px;
}
.custom-margin {
  margin-top: -10px;
}
.custom-margin-2 {
  margin-top: 75px !important;
}
a {
  text-decoration: none;
}

.listClassLight:hover {
  background-color: #fafafa;
}
.listClassDark:hover {
  background-color: #3b355a;
}
.arrow-down {
  transform: rotate(0deg);
  transition: transform 0.1s linear;
}

.arrow-down.open {
  transform: rotate(180deg);
  transition: transform 0.1s linear;
}
</style>
