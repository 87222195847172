var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-between"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"pt-8 pb-8",attrs:{"elevation":"0"}},[_c('div',{staticClass:"text--primary flex-grow-1"},[_c('p',{staticClass:"text--primary text-center text-xl font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("Amount to pay"))+" ")]),(_vm.currency_rate)?_c('p',{staticClass:"text--green text-center text-3xl font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.computedPrice,{ symbol: "Bs ", thousandsSeparator: ",", fractionCount: 2, fractionSeparator: ".", symbolPosition: "front", symbolSpacing: false, avoidEmptyDecimals: undefined, }))+" ")]):_vm._e(),_c('v-divider',{staticClass:"my-4"}),_c('p',{staticClass:"font-weight-bold text-center"},[_vm._v(" "+_vm._s(_vm.$t("Make the payment to"))+": ")]),_c('v-row',{class:[_vm.$vuetify.theme.isDark ? '' : 'bg-light-gray', 'my-1']},[_c('v-col',{staticClass:"pa-1 px-4",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t("Bank"))+" ")]),_c('v-col',{staticClass:"text-end pa-1 font-weight-bold",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.bank.name)+" "),_c('span',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleCopyClipboard(_vm.bank.name, 'showTooltipNameNumber')}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.mdiContentCopy))])],1)]}}],null,true),model:{value:(_vm.showTooltip.showTooltipNameNumber),callback:function ($$v) {_vm.$set(_vm.showTooltip, "showTooltipNameNumber", $$v)},expression:"showTooltip.showTooltipNameNumber"}},[_c('span',[_vm._v(_vm._s(_vm.$t("Bank copied!")))])])],1)])],1),_c('v-row',{class:[_vm.$vuetify.theme.isDark ? '' : 'bg-light-gray', 'my-1']},[_c('v-col',{staticClass:"pa-1 px-4",attrs:{"cols":"4"}},[_vm._v(" RIF ")]),_c('v-col',{staticClass:"text-end pa-1 font-weight-bold",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.bank.rif)+" "),_c('span',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleCopyClipboard(_vm.bank.rif, 'showTooltipRIFHolder')}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.mdiContentCopy))])],1)]}}],null,true),model:{value:(_vm.showTooltip.showTooltipRIFHolder),callback:function ($$v) {_vm.$set(_vm.showTooltip, "showTooltipRIFHolder", $$v)},expression:"showTooltip.showTooltipRIFHolder"}},[_c('span',[_vm._v(_vm._s(_vm.$t("RIF copied!")))])])],1)])],1),_c('v-row',{class:[_vm.$vuetify.theme.isDark ? '' : 'bg-light-gray', 'my-1']},[_c('v-col',{staticClass:"pa-1 px-4",attrs:{"cols":"5"}},[_vm._v(" "+_vm._s(_vm.$t("Phone number"))+" ")]),_c('v-col',{staticClass:"text-end pa-1 font-weight-bold",attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(_vm.bank.number)+" "),_c('span',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleCopyClipboard(
                        _vm.bank.number,
                        'showTooltipNumberHolder'
                      )}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.mdiContentCopy))])],1)]}}],null,true),model:{value:(_vm.showTooltip.showTooltipNumberHolder),callback:function ($$v) {_vm.$set(_vm.showTooltip, "showTooltipNumberHolder", $$v)},expression:"showTooltip.showTooltipNumberHolder"}},[_c('span',[_vm._v(_vm._s(_vm.$t("Phone number copied!")))])])],1)])],1),_c('v-divider',{staticClass:"my-4"}),_c('p',{staticClass:"text-xl font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Enter the details of the pago móvil made"))+" ")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Bank'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.bankList,"item-value":"code","menu-props":{ contentClass: 'list-style' },"dense":"","outlined":"","label":_vm.$t('Bank'),"error-messages":errors[0] && errors[0],"filter":_vm.handleFilterAutocompleteBanks},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var item = ref.item;
return [_c('span',{style:((item.code + item.name).length > 35 &&
                      'font-size: 0.6rem;')},[_vm._v(" "+_vm._s(item.code)+" - "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" - "+_vm._s(item.name)+" ")]}}],null,true),model:{value:(_vm.pagoMovilForm.bank),callback:function ($$v) {_vm.$set(_vm.pagoMovilForm, "bank", $$v)},expression:"pagoMovilForm.bank"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-4",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Operator'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.pagoMovilForm.phonePrefixList,"label":_vm.$t('Operator'),"outlined":"","dense":"","error-messages":errors[0] && errors[0]},model:{value:(_vm.pagoMovilForm.phonePrefix),callback:function ($$v) {_vm.$set(_vm.pagoMovilForm, "phonePrefix", $$v)},expression:"pagoMovilForm.phonePrefix"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Phone'),"rules":"required|min:7|max:7|numeric","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('PhoneInput',{attrs:{"label":_vm.$t('Phone'),"dense":"","outlined":"","long":false,"error-messages":errors[0] && errors[0]},model:{value:(_vm.pagoMovilForm.phone),callback:function ($$v) {_vm.$set(_vm.pagoMovilForm, "phone", $$v)},expression:"pagoMovilForm.phone"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-4",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Payment date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('DatePickerInput',{attrs:{"label":_vm.$t('Payment date'),"placeholder":"Ingrese su Fecha de pago","outlined":"","dense":"","max":_vm.maxDay,"error-messages":errors[0] && errors[0]},model:{value:(_vm.pagoMovilForm.date),callback:function ($$v) {_vm.$set(_vm.pagoMovilForm, "date", $$v)},expression:"pagoMovilForm.date"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Last 4 reference digits'),"rules":"required|min:4|max:4|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"label":_vm.$t('Last 4 reference digits'),"outlined":"","dense":"","hint":_vm.$t('Last 4 reference digits'),"persistent-hint":"","error-messages":errors[0] && errors[0]},model:{value:(_vm.pagoMovilForm.digits),callback:function ($$v) {_vm.$set(_vm.pagoMovilForm, "digits", $$v)},expression:"pagoMovilForm.digits"}})]}}],null,true)})],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Do you want a custom invoice?'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors[0] && errors[0],"label":_vm.$t('Do you want a custom invoice?'),"dense":"","row":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Do you want a custom invoice?"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.pagoMovilForm.customInvoice),callback:function ($$v) {_vm.$set(_vm.pagoMovilForm, "customInvoice", $$v)},expression:"pagoMovilForm.customInvoice"}},[_c('v-radio',{attrs:{"label":_vm.$t('Yes'),"value":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("Yes"))+" ")])]},proxy:true}],null,true)}),_c('v-radio',{staticClass:"text--primary",attrs:{"label":_vm.$t('No'),"value":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("No"))+" ")])]},proxy:true}],null,true)})],1)]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Is this top up related to a proform?'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors[0] && errors[0],"label":_vm.$t('Is this top up related to a proform?'),"dense":"","row":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Is this top up related to a proform?"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.pagoMovilForm.proforma),callback:function ($$v) {_vm.$set(_vm.pagoMovilForm, "proforma", $$v)},expression:"pagoMovilForm.proforma"}},[_c('v-radio',{attrs:{"label":_vm.$t('Yes'),"value":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("Yes"))+" ")])]},proxy:true}],null,true)}),_c('v-radio',{attrs:{"label":_vm.$t('No'),"value":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("No"))+" ")])]},proxy:true}],null,true)})],1)]}}],null,true)}),(_vm.pagoMovilForm.proforma)?_c('ValidationProvider',{attrs:{"name":_vm.$t('Proforma number'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Proforma'),"outlined":"","dense":"","error-messages":errors[0] && errors[0]},model:{value:(_vm.pagoMovilForm.proformaNumber),callback:function ($$v) {_vm.$set(_vm.pagoMovilForm, "proformaNumber", $$v)},expression:"pagoMovilForm.proformaNumber"}})]}}],null,true)}):_vm._e()],1)],1)],1),_c('div',{staticClass:"button-group"},[_c('v-btn',{staticStyle:{"width":"40%"},attrs:{"rounded":"","outlined":""},on:{"click":function($event){return _vm.$emit('prev')}}},[_vm._v(" "+_vm._s(_vm.$t("Back"))+" ")]),_c('v-btn',{staticStyle:{"width":"40%"},attrs:{"rounded":"","color":"primary","disabled":invalid,"loading":_vm.loading},on:{"click":_vm.$handlePagoMovil}},[_vm._v(" "+_vm._s(_vm.$t("Continue"))+" ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }