<template>
  <div class="d-flex flex-column justify-between">
    <ValidationObserver v-slot="{ invalid }">
      <v-card class="pt-8 pb-8" elevation="0">
        <div class="text--primary flex-grow-1">
          <p>
            {{
              $t(
                "Send your payment receipt and related information to process the transaction"
              )
            }}
          </p>
          <v-divider class="my-4"></v-divider>
          <v-row dense>
            <v-col cols="12">
              <ValidationProvider
                :name="$t('Reference')"
                rules="required|max:200"
                v-slot="{ errors }"
                slim
              >
                <v-text-field
                  :label="$t('Reference')"
                  dense
                  outlined
                  v-model="manualTopUpForm.details.reference"
                  :error-messages="errors[0] && errors[0]"
                  clearable
                >
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <ValidationProvider
                :name="$t('Payment date')"
                rules="required"
                v-slot="{ errors }"
                slim
              >
                <DatePickerInput
                  :label="$t('Payment date')"
                  :placeholder="$t('Enter your payment date')"
                  v-model="manualTopUpForm.details.date"
                  outlined
                  dense
                  :max="maxDay"
                  :error-messages="errors[0] && errors[0]"
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <ValidationProvider
                :name="$t('Email to send invoice')"
                rules="required|email|max:200"
                v-slot="{ errors }"
                slim
              >
                <v-text-field
                  :label="$t('Email to send invoice')"
                  dense
                  outlined
                  v-model="manualTopUpForm.details.email"
                  :error-messages="errors[0] && errors[0]"
                  clearable
                >
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense class="mt-2 d-flex justify-center align-center">
            <v-col cols="12">
              <v-text-field
                :label="$t('Amount to add')"
                v-model="data.amount"
                v-money="money"
                :prefix="data.currency === 'bs' ? 'Bs.' : '$'"
                outlined
                dense
                class="pa-2"
                validate-on-blur
                :rules="[
                  (v) => !!v || $t('Amount is required'),
                  (v) => {
                    const numericValue =
                      typeof v === 'number'
                        ? v
                        : parseFloat(data.amount.replace(/,/g, ''));
                    return (
                      numericValue > 0 || $t('Amount must be greater than 0')
                    );
                  },
                ]"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-2 d-flex justify-center align-center">
            <v-col cols="12" md="12">
              <ValidationProvider
                :name="$t('Payment receipt')"
                rules="required"
                v-slot="{ errors }"
                slim
              >
                <v-file-input
                  v-model="manualTopUpForm.details.paymentImage"
                  show-size
                  :truncate-length="$vuetify.breakpoint.mdAndUp ? 50 : 10"
                  :label="$t('Payment receipt')"
                  :error-messages="errors[0] && errors[0]"
                  accept="image/png, image/jpg, image/jpeg"
                  dense
                  outlined
                  @change="previewImage()"
                  ref="file"
                >
                </v-file-input>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <ValidationProvider
                :name="$t('Do you want a custom invoice?')"
                rules="required"
                v-slot="{ errors }"
                slim
              >
                <v-radio-group
                  v-model="manualTopUpForm.details.customInvoice"
                  :error-messages="errors[0] && errors[0]"
                  :label="$t('Do you want a custom invoice?')"
                  dense
                  row
                >
                  <template v-slot:label>
                    <div class="text--primary font-weight-bold">
                      {{ $t("Do you want a custom invoice?") }}
                    </div>
                  </template>
                  <v-radio :label="$t('Yes')" :value="true">
                    <template v-slot:label>
                      <div class="text--primary">
                        {{ $t("Yes") }}
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    class="text--primary"
                    :label="$t('No')"
                    :value="false"
                  >
                    <template v-slot:label>
                      <div class="text--primary">
                        {{ $t("No") }}
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <ValidationProvider
                :name="$t('Is this top up related to a proform?')"
                rules="required"
                v-slot="{ errors }"
                slim
              >
                <v-radio-group
                  :error-messages="errors[0] && errors[0]"
                  :label="$t('Is this top up related to a proform?')"
                  dense
                  row
                  v-model="manualTopUpForm.details.proforma"
                >
                  <template v-slot:label>
                    <div class="text--primary font-weight-bold">
                      {{ $t("Is this top up related to a proform?") }}
                    </div>
                  </template>
                  <v-radio :label="$t('Yes')" :value="true">
                    <template v-slot:label>
                      <div class="text--primary">
                        {{ $t("Yes") }}
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :label="$t('No')" :value="false">
                    <template v-slot:label>
                      <div class="text--primary">
                        {{ $t("No") }}
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </ValidationProvider>
              <ValidationProvider
                v-if="manualTopUpForm.details.proforma"
                :name="$t('Proforma number')"
                rules="required"
                v-slot="{ errors }"
                slim
              >
                <v-text-field
                  v-model="manualTopUpForm.details.proformaNumber"
                  :label="$t('Proforma')"
                  outlined
                  dense
                  :error-messages="errors[0] && errors[0]"
                >
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </div>

        <!-- Botones para volver y continuar -->
        <div class="button-group">
          <v-btn rounded outlined style="width: 40%" @click="$emit('prev')">
            {{ $t("Back") }}
          </v-btn>
          <v-btn
            rounded
            style="width: 40%"
            color="primary"
            @click="handleNext"
            :loading="loading"
            :disabled="invalid || (data.currency === 'usd' && data.amount <= 0)"
          >
            {{ $t("Continue") }}
          </v-btn>
        </div>
      </v-card>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DatePickerInput from "@/components/DatePickerInput";
import axios from "@/plugins/axios";

export default {
  name: "ManualPaymentStep3",
  components: {
    DatePickerInput,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      manualTopUpForm: {
        details: {
          reference: "",
          date: "",
          email: this.email,
          paymentImage: null,
        },
        customInvoice: false,
        proforma: false,
      },
      money: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      loading: false,
    };
  },
  computed: {
    ...mapState("auth", ["email"]),
    ES() {
      return this.$vuetify.lang.current === "es";
    },
    maxDay() {
      return new Date().toISOString().split("T")[0];
    },
  },
  methods: {
    previewImage(e) {
      if (!this.manualTopUpForm.details.paymentImage) {
        return;
      }

      let error = undefined;
      // nulo
      if (!this.manualTopUpForm.details.paymentImage.size) {
        this.manualTopUpForm.details.paymentImage = null;
        return;
      }

      // Menor a 20mb
      if (this.manualTopUpForm.details.paymentImage.size) {
        const fileSize =
          this.manualTopUpForm.details.paymentImage.size / 1024 / 1024; //mb
        if (fileSize > 20) {
          this.$dialog.notify.error(
            this.ES
              ? "<h3>El archivo debe ser menor a 20mb </h3>"
              : "<h3>The file must be lower than 20mb </h3>",
            {
              position: "top-right",
              timeout: 2000,
            }
          );
          error = true;
        }
      }

      // tipos de archivo jpg o png
      let types = ["image/jpg", "image/jpeg", "image/png"];

      if (!types.includes(this.manualTopUpForm.details.paymentImage.type)) {
        this.$dialog.notify.error(
          this.ES
            ? "<h3>El archivo debe ser jpg, jpeg o png </h3>"
            : "<h3>The file must be jpg, jpeg or png </h3>",
          {
            position: "top-right",
            timeout: 2000,
          }
        );
        error = true;
      }

      if (error) {
        this.$nextTick(() => {
          this.manualTopUpForm.details.paymentImage = null;
          this.url = null;
        });
      } else {
        this.url = URL.createObjectURL(
          this.manualTopUpForm.details.paymentImage
        );
      }
    },
    async handleNext() {
      try {
        this.loading = true;
        const { details } = this.manualTopUpForm;
        const formData = new FormData();

        formData.append("currencyId", this.data.currency === "bs" ? 2 : 1);
        formData.append("paymentAt", details.date);
        formData.append(
          "amount",
          parseFloat(this.data.amount.replace(/,/g, ""))
        );
        formData.append("paymentManualUniqueId", this.data.unique_id);
        formData.append("emailSender", details.email);
        formData.append("reference", details.reference);
        formData.append(
          "proforma",
          details.proforma ? details.proformaNumber : ""
        );
        formData.append("invoicePersonalized", !!details.customInvoice);

        if (details.paymentImage) {
          formData.append("paymentImage", details.paymentImage);
        }
        const response = await axios.post(
          `${process.env.VUE_APP_STAGING}/v2/corporate/add-payment-transaction`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.data.waiting = response.data?.success;
      } catch (error) {
        console.log(error);
        this.data.success = false;
      } finally {
        this.$emit("next");
        this.loading = false;
      }
    },
  },
  created() {
    this.manualTopUpForm.details.email = this.email;
  },
};
</script>
<style scoped>
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.text--green {
  color: #2bd4aa !important;
}
.bg-light-gray {
  background-color: #fafafa !important;
}
</style>
