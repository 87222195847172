var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-between"},[_c('div',{staticClass:"text--primary flex-grow-1"},[_c('p',{staticClass:"text--primary text-center text-xl font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Make the payment to"))+": ")]),_c('v-row',{class:[_vm.$vuetify.theme.isDark ? '' : 'bg-light-gray', 'my-1']},[_c('v-col',{staticClass:"pa-1 px-4",attrs:{"cols":"5"}},[_vm._v(" "+_vm._s(_vm.$t("Account holder"))+" ")]),_c('v-col',{staticClass:"text-end pa-1 font-weight-bold",attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(_vm.bank.accountHolder)+" "),_c('span',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleCopyClipboard(
                    _vm.bank.accountHolder,
                    'showTooltipAccountHolder'
                  )}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.mdiContentCopy))])],1)]}}]),model:{value:(_vm.showTooltip.showTooltipAccountHolder),callback:function ($$v) {_vm.$set(_vm.showTooltip, "showTooltipAccountHolder", $$v)},expression:"showTooltip.showTooltipAccountHolder"}},[_c('span',[_vm._v(_vm._s(_vm.$t("Account holder copied!")))])])],1)])],1),_c('v-row',{class:[_vm.$vuetify.theme.isDark ? '' : 'bg-light-gray', 'my-1']},[_c('v-col',{staticClass:"pa-1 px-4",attrs:{"cols":"5"}},[_vm._v(_vm._s(_vm.$t("Email"))+" ")]),_c('v-col',{staticClass:"text-end pa-1 font-weight-bold",attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(_vm.bank.email)+" "),_c('span',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleCopyClipboard(_vm.bank.email, 'showTooltipEmailHolder')}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.mdiContentCopy))])],1)]}}]),model:{value:(_vm.showTooltip.showTooltipEmailHolder),callback:function ($$v) {_vm.$set(_vm.showTooltip, "showTooltipEmailHolder", $$v)},expression:"showTooltip.showTooltipEmailHolder"}},[_c('span',[_vm._v(_vm._s(_vm.$t("Email copied!")))])])],1)])],1),_c('v-row',{class:[_vm.$vuetify.theme.isDark ? '' : 'bg-light-gray', 'my-1']},[_c('v-col',{staticClass:"pa-1 px-4",attrs:{"cols":"5"}},[_vm._v(_vm._s(_vm.$t("Memo number"))+" ")]),_c('v-col',{staticClass:"text-end pa-1 font-weight-bold",attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(_vm.bank.memoNumber)+" "),_c('span',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleCopyClipboard(_vm.bank.email, 'showTooltipMemoNumber')}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.mdiContentCopy))])],1)]}}]),model:{value:(_vm.showTooltip.showTooltipMemoNumber),callback:function ($$v) {_vm.$set(_vm.showTooltip, "showTooltipMemoNumber", $$v)},expression:"showTooltip.showTooltipMemoNumber"}},[_c('span',[_vm._v(_vm._s(_vm.$t("Memo number copied!")))])])],1)])],1),_c('v-divider',{staticClass:"my-4"}),_c('p',{staticClass:"text-xl font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Enter the details of the zelle made"))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('Payment date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('DatePickerInput',{attrs:{"label":_vm.$t('Payment date'),"placeholder":_vm.$t('Enter your payment date'),"outlined":"","dense":"","max":_vm.maxDay,"error-messages":errors[0] && errors[0]},model:{value:(_vm.zelleForm.date),callback:function ($$v) {_vm.$set(_vm.zelleForm, "date", $$v)},expression:"zelleForm.date"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Memo number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Memo number'),"outlined":"","dense":"","error-messages":errors[0] && errors[0]},model:{value:(_vm.zelleForm.memo),callback:function ($$v) {_vm.$set(_vm.zelleForm, "memo", $$v)},expression:"zelleForm.memo"}})]}}])}),_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"label":_vm.$t('Amount sent'),"prefix":"$","outlined":"","dense":""},model:{value:(_vm.zelleForm.amount),callback:function ($$v) {_vm.$set(_vm.zelleForm, "amount", $$v)},expression:"zelleForm.amount"}}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Zelle owner'),"rules":"required|min:2|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Zelle owner'),"outlined":"","dense":"","error-messages":errors[0] && errors[0]},model:{value:(_vm.zelleForm.ownerInput),callback:function ($$v) {_vm.$set(_vm.zelleForm, "ownerInput", $$v)},expression:"zelleForm.ownerInput"}})]}}])}),_c('p',{staticClass:"text--blue blue-border py-3 mt-3"},[_c('v-icon',{staticStyle:{"color":"#16b1ff !important"}},[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")]),_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.$t("The zelle owner is the person or entity that made the payment"))+". ")])],1),_c('v-divider',{staticClass:"my-4"}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Do you want a custom invoice?'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors[0] && errors[0],"label":_vm.$t('Do you want a custom invoice?'),"dense":"","row":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Do you want a custom invoice?"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.zelleForm.customInvoice),callback:function ($$v) {_vm.$set(_vm.zelleForm, "customInvoice", $$v)},expression:"zelleForm.customInvoice"}},[_c('v-radio',{attrs:{"label":_vm.$t('Yes'),"value":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("Yes"))+" ")])]},proxy:true}],null,true)}),_c('v-radio',{staticClass:"text--primary",attrs:{"label":_vm.$t('No'),"value":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("No"))+" ")])]},proxy:true}],null,true)})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Is this top up related to a proform?'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors[0] && errors[0],"label":_vm.$t('Is this top up related to a proform?'),"dense":"","row":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Is this top up related to a proform?"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.zelleForm.proforma),callback:function ($$v) {_vm.$set(_vm.zelleForm, "proforma", $$v)},expression:"zelleForm.proforma"}},[_c('v-radio',{attrs:{"label":_vm.$t('Yes'),"value":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("Yes"))+" ")])]},proxy:true}],null,true)}),_c('v-radio',{attrs:{"label":_vm.$t('No'),"value":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("No"))+" ")])]},proxy:true}],null,true)})],1)]}}])}),(_vm.zelleForm.proforma)?_c('ValidationProvider',{attrs:{"name":_vm.$t('Proforma number'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Proforma'),"outlined":"","dense":"","error-messages":errors[0] && errors[0]},model:{value:(_vm.zelleForm.proformaNumber),callback:function ($$v) {_vm.$set(_vm.zelleForm, "proformaNumber", $$v)},expression:"zelleForm.proformaNumber"}})]}}],null,false,233689039)}):_vm._e()],1),_c('div',{staticClass:"button-group"},[_c('v-btn',{staticStyle:{"width":"40%"},attrs:{"rounded":"","outlined":""},on:{"click":function($event){return _vm.$emit('closePaymentDrawer')}}},[_vm._v(" "+_vm._s(_vm.$t("Back"))+" ")]),_c('v-btn',{staticStyle:{"width":"40%"},attrs:{"rounded":"","color":"primary","disabled":_vm.handleDisabled,"loading":_vm.loading},on:{"click":_vm.handleNextStep}},[_vm._v(" "+_vm._s(_vm.$t("Continue"))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }